::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgb(214, 214, 214); */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dddddd;
}

.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-x-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:root {
  --ck-border-radius: 8px !important;
  --ck-color-base-border: #f2f2f2 !important;
  --ck-focus-ring: 1px solid #f2f2f2 !important;
}

html {
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

ol,
ul,
menu {
  list-style: disc;
  padding: revert;
}

ol.list-type-abc {
  counter-reset: list;
}
ol.list-type-abc > li {
  list-style: none;
}
ol.list-type-abc > li:before {
  content: counter(list, upper-alpha) ") ";
  counter-increment: list;
}

h1 {
  font-size: 32px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  font-weight: 600;
}
h3 {
  font-size: 20.8px;
  font-weight: 500;
}
h4 {
  font-size: 16px;
  font-weight: 400;
}
h5 {
  font-size: 12.8px;
  font-weight: 400;
}
h6 {
  font-size: 11.2px;
  font-weight: 400;
}

select {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4Z"/></svg>')
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.Toastify__toast {
  font-family: "Noto Sans", sans-serif !important;
}

.Toastify__toast-container {
  width: 400px !important;
}

#root {
  position: relative;
}
.ril__navButtons {
  @apply !w-[48px] !h-[48px] !bg-white !p-[8px] !rounded-full !opacity-100 !bg-[length:16px_20px];
}

.ril__navButtonNext {
  @apply !right-[24px] md:!right-[96px];
  background: #fff url(./assets/svg//chevronright.svg) no-repeat center !important;
}

.ril__navButtonPrev {
  @apply !left-[24px] md:!left-[96px];
  background: #fff url(./assets/svg//chevronleft.svg) no-repeat center !important;
}

.ril__toolbar {
  @apply !bg-transparent !px-[24px] md:!px-[96px] !pt-[16px] md:!pt-[64px] !h-auto !w-full;
}

.ril__toolbarLeftSide .ril-toolbar__item__child.ril__toolbarItemChild {
  @apply !text-[16px] !text-white !mt-[-10px];

  height: 50px;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 12px;
}

.ril-toolbar-left.ril__toolbarSide.ril__toolbarLeftSide {
  width: calc(50% - 100px);
}

.ril-toolbar-right.ril__toolbarSide.ril__toolbarRightSide {
  width: calc(50% + 70px);
}

.ril-zoom-in.ril__toolbarItemChild.ril__builtinButton.ril__zoomInButton {
  padding: 12px 24px;
  background: #fff;
  position: relative;
  opacity: 1;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  height: 48px;
  width: 70px;
}

.ril-zoom-in.ril__toolbarItemChild.ril__builtinButton.ril__zoomInButton::before {
  background: #fff url(./assets/svg/zoomin.svg) no-repeat center;
  position: absolute;
  border-right: 1px solid #000;
  padding: 12px 35px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 1;
  content: "";
  width: 18px;
  height: 18px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ril-zoom-out.ril__toolbarItemChild.ril__builtinButton.ril__zoomOutButton {
  padding: 12px 24px;
  position: relative;
  background: #fff;
  opacity: 1;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 48px;
  width: 70px;
}

.ril-zoom-out.ril__toolbarItemChild.ril__builtinButton.ril__zoomOutButton::before {
  background: #fff url(./assets/svg/zoomout.svg) no-repeat center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 1;
  content: "";
  width: 18px;
  height: 18px;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ril__builtinButtonDisabled::before {
  opacity: 0.4 !important;
}

.ril-close.ril-toolbar__item__child.ril__toolbarItemChild.ril__builtinButton.ril__closeButton {
  display: none;
}

/* .ril__toolbarLeftSide .ril-toolbar__item__child.ril__toolbarItemChild::before {
  content: " ";
  background-image: url(./assets/svg/image_black.svg);
  width: 24px;
  height: 24px;
  display: inline-block;
} */

/* .ril__toolbarLeftSide .ril-toolbar__item.ril__toolbarItem::before {
  content: " ";
  background-image: url(./assets/svg/arrowleft.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-position: center;
  margin-right: 18px;
} */
.missionheading {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.missiondetails {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rdt_TableHeader {
  overflow: visible !important;
}

.rdt_TableHeader > div:last-child {
  display: none;
}

.editortabledata table {
  @apply my-[20px] max-w-[480px] w-full border-[#484848] border-[1px];
}

.editortabledata table thead th:nth-child(odd) {
  @apply border-[1px] border-l-[0px] border-t-[0px] border-[#ECECEC] p-[16px] text-[16px] font-semibold bg-[#F6F6F6] text-[#000E14];
}

.editortabledata table thead th:nth-child(even) {
  @apply border-[1px] border-r-[0px] border-t-[0px] border-[#ECECEC] p-[16px] text-[16px] font-semibold bg-[#F6F6F6] text-[#000E14];
}

.editortabledata table td:nth-child(odd) {
  @apply border-[1px] border-l-[0px] border-[#ECECEC] p-[16px] text-[16px] text-[#000E14] text-center;
}

.editortabledata table td:nth-child(even) {
  @apply border-[1px] border-r-[0px] border-[#ECECEC] p-[16px] text-[16px] text-[#000E14] text-center;
}

.editortabledata table tr:nth-last-child(1) > td:nth-child(odd) {
  @apply border-[1px] border-l-[0px] border-[#ECECEC] p-[16px] text-[16px] text-[#000E14] text-center border-b-[0px];
}

.editortabledata table tr:nth-last-child(1) > td:nth-child(even) {
  @apply border-[1px] border-r-[0px] border-[#ECECEC] p-[16px] text-[16px] text-[#000E14] text-center border-b-[0px];
}

.editortabledata table tr:first-child > td:first-child {
  @apply border-[1px] border-t-[0px] border-l-[0px] border-[#ECECEC] p-[16px] text-[16px] text-[#000E14] text-center border-b-[0px];
}

.editortabledata table tr:first-child > td {
  @apply border-[1px] border-t-[0px] border-[#ECECEC] p-[16px] text-[16px] text-[#000E14] text-center border-b-[0px];
}

.editortabledata table tr:first-child > td:last-child {
  @apply border-[1px] border-t-[0px] border-r-[0px] border-[#ECECEC] p-[16px] text-[16px] text-[#000E14] text-center border-b-[0px];
}

.ck.ck-toolbar__items {
  @apply !p-[20px];
}

.ck.ck-editor__editable_inline > :first-child {
  @apply !mt-0;
}

.ck.ck-editor__editable_inline {
  @apply !px-[32px] !py-[24px];
}

.ck.ck-icon {
  @apply !text-[12px] !cursor-pointer;
}

.ck.ck-toolbar > .ck-toolbar__items > :not(.ck-toolbar__line-break) {
  @apply !mr-[24px];
}

.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  @apply min-h-[400px];
}
.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  box-shadow: none;
}

.datatableWithDropdown > header + div {
  @apply overflow-x-visible overflow-y-visible;
}

.datatableWithDropdown > header + div > div {
  @apply overflow-visible;
}

/* #react-doc-viewer{
  position: absolute;
    top: 104px;
    height: auto;
    min-height: calc(100vh - 104px);
}

#react-doc-viewer > #header-bar{
      max-width: 1030px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
}

#react-doc-viewer > #proxy-renderer{
      max-width: 1030px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      
} */

/* buy plot autocomplete */

.pac-container {
  @apply ml-[-50px] mt-[20px] divide-y absolute top-[120px] z-50 bg-[#fff] min-w-[400px] py-[8px] shadow-lg rounded-regular transform transition duration-100 ease-in-out origin-top;
}

.pac-logo:after {
  @apply hidden;
}
.pac-item {
  @apply w-full text-start py-[8px] px-[16px] bg-[#fff] hover:bg-[#efefef] cursor-pointer outline-none text-[12px] font-medium;
  font-family: "Noto Sans", sans-serif !important;
}
.pac-matched {
  font-size: 14px;
  font-weight: 700;
  font-family: "Noto Sans", sans-serif !important;
}
.pac-item-query {
  font-size: 14px;
  padding-right: 3px;
}

.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}
